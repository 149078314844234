import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import detail from '../views/detail.vue'
import share from '../views/share.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/detail',
    name: 'detail',
    component: detail,
  },
  {
    path: '/share',
    name: 'share',
    component: share,
  },
]


const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
