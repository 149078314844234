<template>
  <div class="container" id="share">
    <div class="head">
      <img
          class="logo"
          src="	https://ningmeng-info.oss-cn-guangzhou.aliyuncs.com/file/6971d07b-36c2-4f56-add1-57be340e9cd4.png"
          alt
      />
      <img class="title" src="../assets/shareTitle.png" alt/>
    </div>

    <div class="conten">
      <div class="avater">
        <div class="avaterItem">
          <img v-if="params.avatar != 'undefined'" :src="params.avatar" alt/>
        </div>

        <img class="crown" src="../assets/path.png" alt/>
      </div>

      <div class="name">{{ params.name }}</div>

      <div class="id">ID：{{ params.uid }}</div>

      <div class="but" @click="apply">{{ params.type == 1 ? '进入房间与我互动' : '来圈圈与我互动' }}</div>

      <img class="rbimg" src="../assets/rightbot.png" alt/>
    </div>

    <div class="qrcode">
      <vueqr :text="qrCode" :size="120"></vueqr>
    </div>

    <div class="font">扫码下载圈圈开黑</div>
  </div>
</template>

<script>
// import autofit from "../utils/autofit.js";
import vueqr from 'vue-qr'

export default {
  data() {
    return {
      params: null,
      qrCode: "https://quanquan.ningmengxinxi.cn/",
    };
  },

  components: {vueqr},

  created() {
    let url = document.location.toString();
    let arrObj = url.split("?");
    let param = Object.create(null);
    if (arrObj.length > 1) {
      arrObj = decodeURI(arrObj[1]).split("&");
      arrObj.forEach((item) => {
        item = item.split("=");
        param[item[0]] = item[1];
      });
    }
    this.params = param;
    this.params.avatar = decodeURIComponent(this.params.avatar);
    console.log(this.params.avatar);
  },

  mounted() {
    this.generateQRCode();
    // autofit.init(
    //     {
    //       el: "#share",
    //       dw: 375,
    //       dh: 724,
    //       resize: true,
    //     },
    //     false
    // );
  },

  methods: {
    generateQRCode() {
      var canvas = document.getElementById('canvas')
      QRCode.toCanvas(canvas, 'https://quanquan.ningmengxinxi.cn/', function (error) {
      })
    },

    apply() {
      location.href = "https://quanquan.ningmengxinxi.cn/"
    },

    isMobile() {
      let flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(-45deg, rgb(255, 110, 17) 0%, rgb(255, 206, 0) 100%);
  padding: 0 20px;
  box-sizing: border-box;

  .head {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 36px;

    .logo {
      width: 27px;
      height: 27px;
      margin-right: 6px;
    }

    .title {
      width: 84px;
      height: 16px;
    }
  }

  .conten {
    margin: 0 auto;
    margin-top: 36px;
    width: 100%;
    border-radius: 8px;
    padding-top: 65px;
    padding-bottom: 73px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg,
    rgb(255, 244, 206),
    rgb(255, 255, 255) 50.281%);
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.16);
    position: relative;

    .avater {
      width: 102px;
      height: 102px;
      position: relative;

      .avaterItem {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        z-index: 99;
        position: absolute;
        background-color: #fff;
        border: 2px solid #f74b99;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 95%;
          height: 95%;
          border-radius: 50%;
        }
      }

      .crown {
        position: absolute;
        width: 30px;
        height: 30px;
        right: -6px;
        top: -6px;
        z-index: 20;
      }
    }

    .name {
      margin-top: 22px;
      color: #343537;
      font-size: 18px;
      font-weight: 600;
    }

    .id {
      margin-top: 6px;
      color: #979797;
      font-size: 14px;
    }

    .but {
      padding: 12px 31px;
      box-sizing: border-box;
      border: 1px solid #ff870d;
      border-radius: 30px;
      margin-top: 32px;
      color: #ff870d;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 1px;
    }

    .rbimg {
      position: absolute;
      width: 99px;
      height: 90px;
      bottom: -6px;
      right: -5px;
    }
  }

  .qrcode {
    width: 88px;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: #fff;
    margin: 0 auto;
    margin-top: 50px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .font {
    color: #343537;
    font-size: 14px;
    margin: 0 auto;
    margin-top: 16px;
  }
}
</style>
