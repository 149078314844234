<template>
  <div>
    <div class="container" :style="'height:'+height+'px'" id="foot" @touchmove='touchmove'>
      <div class="title">联系我们</div>
      <div class="solid"></div>

      <div class="card">
        <img src="../../assets/em.png" style="width: 17px;height: 13px;" alt/>
        <div class="label">电子邮箱</div>
      </div>

      <div class="msg">{{ data.business_compayny_email }}</div>

      <div class="card" style="align-items: normal;">
        <img src="../../assets/address.png" style="width: 13px;height: 17px;" alt=""/>
        <div class="label">办公地址</div>
      </div>

      <div class="msg" style="width: 220px;hright: 42px;margin:0.6rem auto 0;">{{ data.business_company_address }}</div>

      <div class="titlebot">协议政策</div>
      <div class="solid"></div>

      <div class="containerFoot">
        <div class="containerFootLeft" @click="change(data.business_agreement_user_vip_agreement)">开通会员协议</div>
        <div class="containerFootLeft" @click="change(data.business_agreement_user_agreement)">用户注册协议</div>
      </div>

      <div class="containerFoot" style="margin-top: 0.3rem;">
        <div class="containerFootLeft" @click="change(data.business_agreement_privacy_agreement)">隐私协议</div>
        <div class="containerFootLeft" @click="change(data.business_agreement_recharge_agreement)">充值协议</div>
      </div>

      <div class="containerFoot" style="margin-top: 0.3rem;">
        <div class="containerFootLeft" @click="change(data.business_agreement_post_agreement)">动态发布准则</div>
         <div class="containerFootLeft" @click="change(993)">处罚规则</div>
      </div>

      <div class="containerSolid"></div>

      <div style="width: 342px;margin: 8px auto 0;overflow: hidden;">
        <div style="display: flex;justify-content: space-between;">
          <div class="footFont" v-if="data.business_compayny_email != 'null'">
            举报邮箱{{ data.business_compayny_email }}
          </div>
        </div>
        <div class="footFont" v-if="data.business_company_name != 'null'">公司名称:{{ data.business_company_name }}
        </div>
        <div class="footFont" v-if="data.business_company_copyright != 'null' ">{{ data.business_company_copyright }}
        </div>
        <div class="footFont" v-if="data.business_icp != 'null' ">{{ data.business_icp }}</div>
        <div class="footFont" v-if="data.business_zengzhidianxin != 'null'">
          增值电信业务营业许可证{{ data.business_zengzhidianxin }}
        </div>
        <div class="footFont" @click="go"
             v-if="data.business_compayny_wangbei != 'null' && data.business_zengzhidianxin != 'null'">
          <img src="../../assets/pole.png" style="width: 18px;height: 18px;" alt/>
          {{ data.business_compayny_wangbei }}增值电信业务营业许可证{{ data.business_zengzhidianxin }}
        </div>
        <div class="footFont" style="white-space: normal;line-height: 20px;">
          公司地址:{{ data.business_company_address }}
        </div>
      </div>

      <div class="containerBot"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: window.innerHeight,
      data: {},
    };
  },

  created() {
    this.getData()
  },

  methods: {
    getData() {
      this.$axios.post('https://quanquan.ningmengxinxi.cn/api/v1/common/config').then((res) => {
        this.data = res.data.data
      })
    },

    change(id) {
      this.$router.push({path: '/detail', query: {id}})
    },

    touchmove() {
      this.$emit("show")
    },

    go() {
      location.href = this.data.business_compayny_wangbei_url
    },
  }
};
</script>

<style scoped>
.container {
  width: 100%;
  background-color: #f7f7f7;
  padding-top: 100px;
  box-sizing: border-box;
}

.title {
  color: #343537;
  font-size: 16px;
  font-family: ".苹方-简";
  text-align: center;
  font-weight: 600;
}

.solid {
  width: 36px;
  height: 3px;
  background-color: #343537;
  margin: 0 auto;
  margin-top: 0.8rem;
}

.card {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label {
  margin-left: 9px;
  font-size: 14px;
  color: #343537;
}

.msg {
  color: #949494;
  text-align: center;
  font-size: 14px;
  margin-top: 0.6rem;
}

.titlebot {
  color: #343537;
  font-size: 16px;
  font-family: ".苹方-简";
  text-align: center;
  font-weight: 600;
  margin-top: 1.5rem;
}

.containerFoot {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #343537;
  width: 242px;
  margin: 0 auto;
  margin-top: 0.5rem;
}

.containerFootLeft {
  width: 85px;
  text-align: left;
}

.containerSolid {
  width: 335px;
  border: 1px #d1d1d1 solid;
  margin: 2.2rem auto 0;
}

.footFont {
  /* white-space: nowrap; */
  display: flex;
  /* align-items: center; */
  color: #666666;
  font-size: 11px;
  line-height: 16px;
  margin-top: 5px !important;
}

.containerBot {
  position: absolute;
  bottom: 0;
  background-color: #ff850e;
  width: 100%;
  height: 36px;
}
</style>
