<script>
export default {
  name: "downloadList",
  props: ['isAndroid', 'androidDow', 'iosDow', 'isTip', 'iosTfAddress'],
  methods: {
    handleTF() {
      if (!this.iosTfAddress) {
        this.$message({
          message: '暂未开放',
          center: true,
          type: 'warning',
          offset: 300,
          customClass: 'myBox'
        });
        return
      }
      location.href = this.iosTfAddress
    },
    handleStore() {
      location.href = 'https://apps.apple.com/cn/app/%E5%9C%88%E5%9C%88%E5%BC%80%E9%BB%91/id6475077237'
    },
    appDow(type) {
      if (this.isTip) {
        this.$emit("showTip")
        return
      }

      if (type) {
        if (this.iosDow == 'null' || this.iosDow == null) {
          this.$message({
            message: '暂未开放',
            center: true,
            type: 'warning',
            offset: 300,
            customClass: 'myBox'
          });
          return
        }
        location.href = this.iosDow
      } else {
        if (this.androidDow == 'null') {
          this.$message({
            message: '暂未开放',
            center: true,
            type: 'warning',
            offset: 300,
          });
          return
        }
        location.href = this.androidDow
      }
    },
  }
}
</script>

<template>
  <div class="type">
    <div class="typeBut" @click="handleStore">
      <img src="../../assets/ios.png" class="icon-app" alt="">
      <p>IOS商店</p>
    </div>
    <div class="typeBut" @click="handleTF">
      <img src="../../assets/ios.png" class="icon-app" alt="">
      <p>IOS稳定</p>
    </div>
    <div class="typeBut android" @click="appDow(false)">
      <img src="../../assets/android.png" class="icon-app" alt="">
      <p>Android</p>
    </div>
  </div>
</template>

<style lang="less" scoped>
.type {
  margin-top: 24px;
  display: flex;
  justify-content: space-evenly;

  .icon-app {
    width: 14px;
    height: 16px;
    margin-right: 8px;
  }
}

.typeBut {
  width: 108px;
  height: 44px;
  background-color: #313131;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-size: 14px;
  color: #FFFFFF;

  &.android {
    background-color: #FF850E;
  }
}
</style>
