<template>
  <div>
    <div :style="'height:'+ height +'px'" style="overflow: auto;" v-html="detail"></div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      detail: null,
      height: window.innerHeight
    }
  },

  mounted(){
    let id = this.$route.query.id
    this.$axios.post('https://quanquan.ningmengxinxi.cn/api/v1/article/detail',{id}).then((res) => {
      this.detail = res.data.data.content
    })
  },
}
</script>

<style>

</style>