<template>
  <div style="height: 100%;" class="container" id="pageTwo" @touchmove='touchmove'>

    <div style="height: 52px;"></div>

    <div class="fontOneTop">
      <div class="solid"></div>

      <div class="oneTopText">
        直播热聊 找寻心动的声音
      </div>
    </div>

    <div class="fontOne" style="font-size: 18px;">体验多人在线语言群聊</div>
    <div class="fontOne" style="font-size: 16px;margin-top: 12px;font-weight: 400;color: #979797;">
      随刻线上陪伴，温暖每个日夜
    </div>

    <download-list @showTip="showTip"
                   :isTip="isTip"
                   :isAndroid="isAndroid"
                   :androidDow="androidDow"
                   :iosDow="iosDow"
                   :iosTfAddress="iosTfAddress"
    />

    <div style="display: flex;justify-content: center;">
      <div class="mobile">
        <img src="../../assets/mobile.png" style="width: 281px;height: 567px;" alt="">

        <img src="../../assets/pageHome.png" style="border-radius: 32px;" class="mobileHome" alt="">

        <div class="mobileTop">
          <img src="https://ningmeng-info.oss-cn-guangzhou.aliyuncs.com/file/6971d07b-36c2-4f56-add1-57be340e9cd4.png"
               style="width: 16px;height: 16px;margin-left: 3px;" alt="">
        </div>

        <img src="../../assets/tag.png" class="tag" alt="">

        <img src="../../assets/tag2.png" class="tag2" alt="">

        <img src="../../assets/tag3.png" class="tag3" alt="">

        <img src="../../assets/tag4.png" class="tag4" alt="">
      </div>
    </div>
  </div>
</template>

<script>

import DownloadList from "@/views/components/downloadList.vue";

export default {
  components: {DownloadList},
  data() {
    return {
      height: window.innerHeight,
    }
  },

  props: ['isAndroid', 'androidDow', 'iosDow', 'isTip', 'iosTfAddress'],

  mounted() {
  },

  methods: {
    touchmove() {
      this.$emit("show")
    },
    showTip() {
      this.$emit("showTip")
    },

    appDow(type) {
      if (this.isTip) {
        this.$emit("showTip")
        return
      }

      if (type) {
        if (this.iosDow == 'null') {
          this.$message({
            message: '暂未开放',
            center: true,
            type: 'warning',
            offset: 300,
            customClass: 'myBox'
          });
          return
        }
        location.href = this.iosDow
      } else {
        if (this.androidDow == 'null') {
          this.$message({
            message: '暂未开放',
            center: true,
            type: 'warning',
            offset: 300,
            customClass: 'myBox'
          });
          return
        }
        location.href = this.androidDow
      }
    },

  }
}
</script>

<style scoped>
.container {
    overflow: hidden;
    width: 100%;
    background: linear-gradient(to bottom right, #FFEDDB, #FFFFFF, #FFFFFF, #E5E5E5)
}

.head {
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    margin-left: 8px;
    width: 98.2px;
    height: 18.78px;
}

.button {
    width: 88px;
    height: 32px;
    border-radius: 20px;
    background: rgb(255, 133, 14);
    font-family: '.苹方-简';
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    margin-right: 16px;
}

.fontOneTop {
    margin-top: 48px;
    position: relative;
    height: 25px;
}

.solid {
    position: absolute;
    background-color: #FF850E;
    width: 276px;
    height: 8px;
    border-radius: 12px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.oneTopText {
    font-family: '.苹方-简';
    font-weight: 600;
    font-size: 24px;
    color: #343537;
    bottom: 0;
    position: absolute;
    z-index: 89;
    left: 50%;
    width: 280px;
    text-align: center;
    transform: translateX(-50%);
}

.fontOne {
    font-family: '.苹方-简';
    font-weight: 600;
    font-size: 24px;
    color: #343537;
    text-align: center;
    margin-top: 52px;
    margin-top: 6px;
}

.mobile {
    margin-top: 42px;
    display: flex;
    justify-content: center;
    position: relative;
}

.mobileHome {
    width: 251px;
    height: 542px;
    position: absolute;
    border-radius: 32px;
    top: 13px;
}

.mobileTop {
    position: absolute;
    background-color: #000000;
    width: 74.76px;
    height: 21.2px;
    z-index: 80;
    top: 17px;
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.tag {
    width: 130px;
    height: 38px;
    position: absolute;
    top: 142px;
    left: -42px;
}

.tag2 {
    position: absolute;
    width: 102px;
    height: 38px;
    top: 212px;
    right: -36px;
}

.tag3 {
    position: absolute;
    width: 200px;
    height: 38px;
    top: 265px;
    left: -30px;
}

.tag4 {
    position: absolute;
    width: 200px;
    height: 38px;
    top: 352px;
    right: -42px;
}
</style>
