<template>
  <div id="wrap">
    <div class="head">
      <div style="display: flex;align-items: center;">
        <img src="https://ningmeng-info.oss-cn-guangzhou.aliyuncs.com/file/6971d07b-36c2-4f56-add1-57be340e9cd4.png"
             style="width: 28px;height: 28px;margin-left: 16px;" alt="">
        <div class="title"><img src="../assets/title.png" style="width: 100%;height: 100%;" alt=""></div>
      </div>
      <div class="showbutton" @click.stop="showDow">
        <div class="buttonsolid"></div>
        <div class="buttonsolid"></div>
        <div class="buttonsolid"></div>

        <div class="buttonConten" v-show="isShow">
          <div class="buttonFont" @click.stop="recharge" style="margin-top: 6px;">充值入口</div>
        </div>
      </div>
    </div>

    <div class="tip" v-show="showT">
      <img src="../assets/img_h5_guide.png" alt="">
    </div>

    <div :class="['swiper-container', isSafariIos ? 'safariTop' : '']">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <page @show="show"
                @showTip="showTip"
                :isTip="isTip"
                :isAndroid="isAndroid"
                :androidDow="androidDow"
                :iosDow="iosDow"
                :iosTfAddress="iosTfAddress"
          />
        </div>
        <div class="swiper-slide">
          <page-two @show="show"
                    @showTip="showTip"
                    :isTip="isTip"
                    :isAndroid="isAndroid"
                    :androidDow="androidDow"
                    :iosDow="iosDow"
                    :iosTfAddress="iosTfAddress"

          />
        </div>
        <div class="swiper-slide">
          <page-three @show="show"
                      @showTip="showTip"
                      :isTip="isTip"
                      :isAndroid="isAndroid"
                      :androidDow="androidDow"
                      :iosDow="iosDow"
                      :iosTfAddress="iosTfAddress"
          />
        </div>
        <div class="swiper-slide">
          <foot @show="show"></foot>
        </div>
      </div>
      <div class="swiper-pagination"/>
    </div>
  </div>
</template>

<script>
import page from "./components/page.vue";
import pageTwo from "./components/pageTwo.vue";
import pageThree from "./components/pageThree.vue";
import foot from "./components/foot.vue";
import Swiper from "swiper";
import "swiper/swiper.min.css";
import {isSafari} from "@/utils/isSafari";

export default {
  components: {
    page,
    pageTwo,
    pageThree,
    foot,
  },
  data() {
    return {
      isAndroid: null,
      androidDow: '',
      iosDow: '',
      rechargeUrl: '',
      height: window.outerHeight,
      width: window.innerWidth,
      mySwiper: null,
      isShow: false,
      isTip: false,
      showT: false,
      iosTfAddress: '',
      isSafariIos: false
    };
  },

  created() {
    let user = navigator.userAgent;
    this.isAndroid = user.indexOf('Android') > -1 || user.indexOf('Adr') > -1 ? true : false;
    this.isTip = user.toLowerCase().indexOf('micromessenger') > -1
    !this.isAndroid && user.toLowerCase().indexOf('safari') < -1 ? this.isTip = true : null
    this.getData()
    this.isSafariIos = isSafari()
  },

  mounted() {
    this.$nextTick(() => {
      this.mySwiper = new Swiper(".swiper-container", {
        direction: "vertical", // 垂直切换
        autoHeight: false,
        slidesPerView: 'auto',
        mousewheel: true, // 鼠标滚轮翻页
        loop: false, // 循环模式选项
        centeredSlides: true,
        centeredSlidesBounds: true,
        pagination: { // 分页
          clickable: true,
          el: '.swiper-pagination',
        },
      });
    });
  },

  methods: {
    showTip() {
      this.showT = true
    },

    showDow() {
      this.isShow = !this.isShow
    },

    show() {
      this.isShow = false
    },

    getData() {
      this.$axios.post('https://quanquan.ningmengxinxi.cn/api/v1/common/config').then((res) => {
        this.androidDow = res.data.data.business_android_address
        this.iosDow = res.data.data.business_ios_address
        this.rechargeUrl = res.data.data.business_recharge_link
        this.iosTfAddress = res.data.data.business_ios_tf_address
      })
    },

    recharge() {
      location.href = this.rechargeUrl
    },

  },
};
</script>

<style scoped lang='less'>
#wrap {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.head {
  position: fixed;
  top: 0;
  z-index: 80;
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: linear-gradient(to bottom right, #FFEDDB, #FFFFFF)
}

.tip {
  position: absolute;
  z-index: 99;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: flex-end;

  img {
    width: 321px;
    height: 239px;
  }
}

.title {
  margin-left: 8px;
  width: 98.2px;
  height: 18.78px;
}

.showbutton {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.buttonsolid {
  width: 20px;
  height: 1.5px;
  border-radius: 16px;
  background-color: #313131;
  margin-bottom: 6px;
}

.buttonConten {
  position: absolute;
  bottom: -86px;
  left: -66px;
  width: 88px;
  height: 76px;
  background-image: url('@/assets/bgBut.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  z-index: 20;
}

.buttonFont {
  color: #fff;
  font-size: 14px;
}

.buttonConSolid {
  height: 1px;
  width: 100%;
  background-color: #737373;
}

.swiper-container {
  height: 100vh !important;
  box-sizing: border-box;
  --swiper-theme-color: #ff6600;
  --swiper-pagination-color: #007aff; /* 两种都可以 */
}

.safariTop {
  padding-top: 60px !important;
}

.swiper-wrapper {
  height: 100% !important;
}

.swiper-slide {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
</style>
