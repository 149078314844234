<template>
  <div style="height: 100%;" class="container" id="page" @touchmove='touchmove'>

    <div style="height: 52px;"></div>

    <div class="fontOne">技术流大神，默契队友带你Carry全场</div>
    <div class="fontOne" style="font-size: 22px;margin-top: 11px;font-weight: 600;">圈圈开黑 与快乐组队 欢笑不落单</div>
    <div class="fontOne" style="font-size: 18px;margin-top: 12px;font-weight: 600;">以兴趣速配，让缘分相遇</div>

    <download-list @showTip="showTip"
                   :isTip="isTip"
                   :isAndroid="isAndroid"
                   :androidDow="androidDow"
                   :iosDow="iosDow"
                   :iosTfAddress="iosTfAddress"
    />
    <!--    <div class="type">
          <div class="typeBut" @click="appDow(true)">
            <img src="../../assets/ios.png" class="icon-app" alt="">
            <p>IOS稳定</p>
          </div>
          <div class="typeBut" @click="appDow(true)">
            <img src="../../assets/ios.png" class="icon-app" alt="">
            <p>IOS最新</p>
          </div>

          <div class="typeBut android" @click="appDow(false)">
            <img src="../../assets/android.png" class="icon-app" alt="">
            <p>Android</p>
          </div>
        </div>-->

    <div class="link" @click="link">
      <img src="../../assets/pathText.png" style="width: 14px;height: 14px;margin-right: 5px;" alt="">
      “未受信任的企业级开发者”的解决办法
    </div>

    <div class="mobile">
      <img src="../../assets/mobile.png" style="width: 281px;height: 567px;" alt="">

      <img src="../../assets/home.png" style="border-radius: 32px;" class="mobileHome" alt="">

      <div class="mobileTop">
        <img src="https://ningmeng-info.oss-cn-guangzhou.aliyuncs.com/file/6971d07b-36c2-4f56-add1-57be340e9cd4.png"
             style="width: 16px;height: 16px;margin-left: 3px;" alt="">
      </div>
    </div>
  </div>
</template>

<script>

import DownloadList from "@/views/components/downloadList.vue";

export default {
  components: {DownloadList},

  data() {
    return {
      height: window.innerHeight,
    }
  },

  props: ['isAndroid', 'androidDow', 'iosDow', 'isTip', 'iosTfAddress'],

  created() {
  },

  methods: {
    link() {
      location.href = "https://quanquan.ningmengxinxi.cn/wei/"
    },

    touchmove() {
      this.$emit("show")
    },
    showTip() {
      this.$emit("showTip")
    },

    appDow(type) {
      if (this.isTip) {
        this.$emit("showTip")
        return
      }

      if (type) {
        if (this.iosDow == 'null' || this.iosDow == null) {
          this.$message({
            message: '暂未开放',
            center: true,
            type: 'warning',
            offset: 300,
            customClass: 'myBox'
          });
          return
        }
        location.href = this.iosDow
      } else {
        if (this.androidDow == 'null') {
          this.$message({
            message: '暂未开放',
            center: true,
            type: 'warning',
            offset: 300,
          });
          return
        }
        location.href = this.androidDow
      }
    },
  }

}
</script>

<style>
.myBox {
    min-width: 80% !important;
}

</style>

<style scoped lang="less">
.container {
  overflow: hidden;
  width: 100%;
  background: linear-gradient(to bottom right, #FFEDDB, #FFFFFF, #FFFFFF, #E5E5E5)
}

.head {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin-left: 8px;
  width: 98.2px;
  height: 18.78px;
}

.button {
  width: 88px;
  height: 32px;
  border-radius: 20px;
  background: rgb(255, 133, 14);
  font-family: '.苹方-简';
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  color: #FFFFFF;
  margin-right: 16px;
}

.fontOne {
  font-family: '.苹方-简';
  font-size: 14px;
  color: #343537;
  text-align: center;
  margin-top: 33px;
}


.link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  color: #3B82F6;
  font-size: 14px;
}

.mobile {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  position: relative;
}

.mobileHome {
  width: 251px;
  height: 542px;
  position: absolute;
  border-radius: 32px;
  top: 13px;
}

.mobileTop {
  position: absolute;
  background-color: #000000;
  width: 74.76px;
  height: 21.2px;
  z-index: 80;
  top: 17px;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

</style>
