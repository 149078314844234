<template>
  <div style="height: 100%;" class="container" id="pageThree" @touchmove='touchmove'>

    <div style="height: 52px;"></div>

    <div class="fontOneTop">
      <div class="solid"></div>

      <div class="oneTopText">
        参与有趣话题 遇见知心的TA
      </div>
    </div>

    <div class="fontOne" style="font-size: 18px;margin-top: 6px;">互动热评 说说你的看法</div>
    <div class="fontOne" style="font-size: 16px;margin-top: 12px;font-weight: 400;color: #979797;">
      分享美好生活，打发无聊时光
    </div>


    <download-list @showTip="showTip"
                   :isTip="isTip"
                   :isAndroid="isAndroid"
                   :androidDow="androidDow"
                   :iosDow="iosDow"
                   :iosTfAddress="iosTfAddress"
    />

    <div style="display: flex;justify-content: center;">
      <div class="mobile">
        <img src="../../assets/mobile.png" style="width: 281px;height: 567px;" alt="">

        <img src="../../assets/pageHomeThree.png" style="border-radius: 32px;" class="mobileHome" alt="">

        <div class="mobileTop">
          <img src="https://ningmeng-info.oss-cn-guangzhou.aliyuncs.com/file/6971d07b-36c2-4f56-add1-57be340e9cd4.png"
               style="width: 16px;height: 16px;margin-left: 3px;" alt="">
        </div>

        <div class="threeTag">
          <div class="add">
            <div class="addFont">+</div>
          </div>
          <div>发动态</div>
        </div>

        <div class="threeTag" style="top: 266px;left: -30px;">
          <div class="add">
            <div class="addFont">+</div>
          </div>
          <div>新话题</div>
        </div>

        <div class="threeTag" style="opacity: 0.6;top: 351px;right: -26px;">
          <div class="add">
            <div class="addFont">+</div>
          </div>
          <div>晚安语录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DownloadList from "@/views/components/downloadList.vue";

export default {
  components: {DownloadList},
  data() {
    return {
      height: window.innerHeight,
    }
  },

  props: ['isAndroid', 'androidDow', 'iosDow', 'isTip', 'iosTfAddress'],

  methods: {
    touchmove() {
      this.$emit("show")
    },
    showTip(){
      this.$emit("showTip")
    },
    appDow(type) {
      if (this.isTip) {
        this.$emit("showTip")
        return
      }

      if (type) {
        if (this.iosDow == 'null') {
          this.$message({
            message: '暂未开放',
            center: true,
            type: 'warning',
            offset: 300,
            customClass: 'myBox'
          });
          return
        }
        location.href = this.iosDow
      } else {
        if (this.androidDow == 'null') {
          this.$message({
            message: '暂未开放',
            center: true,
            type: 'warning',
            offset: 300,
            customClass: 'myBox'
          });
          return
        }
        location.href = this.androidDow
      }
    },
  }
}
</script>

<style lang="less" scoped>
.container {
  overflow: hidden;
  width: 100%;
  background: linear-gradient(to bottom right, #FFEDDB, #FFFFFF, #FFFFFF, #E5E5E5)
}

.head {
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  background: linear-gradient(to bottom right, #FFEDDB, #FFFFFF);
}

.title {
  margin-left: 8px;
  width: 98.2px;
  height: 18.78px;
}

.button {
  width: 88px;
  height: 32px;
  border-radius: 20px;
  background: rgb(255, 133, 14);
  font-family: '.苹方-简';
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  color: #FFFFFF;
  margin-right: 16px;
}

.fontOneTop {
  margin-top: 48px;
  position: relative;
  height: 25px;
}

.solid {
  position: absolute;
  background-color: #FF850E;
  width: 310px;
  height: 8px;
  border-radius: 12px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.oneTopText {
  font-family: '.苹方-简';
  font-weight: 600;
  font-size: 24px;
  color: #343537;
  bottom: 0;
  position: absolute;
  z-index: 89;
  left: 50%;
  width: 320px;
  text-align: center;
  transform: translateX(-50%);
}

.fontOne {
  font-family: '.苹方-简';
  font-weight: 600;
  font-size: 24px;
  color: #343537;
  text-align: center;
  margin-top: 52px;
}

.mobile {
  margin-top: 47px;
  display: flex;
  justify-content: center;
  position: relative;
}

.mobileHome {
  width: 251px;
  height: 542px;
  position: absolute;
  border-radius: 32px;
  top: 13px;
}

.mobileTop {
  position: absolute;
  background-color: #000000;
  width: 74.76px;
  height: 21.2px;
  z-index: 80;
  top: 17px;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.threeTag {
  font-weight: 500;
  width: 96px;
  height: 36px;
  position: absolute;
  top: 91px;
  right: -30px;
  background: linear-gradient(-45.00deg, rgb(255, 206, 0) 0%, rgb(255, 110, 17) 100%);
  box-shadow: 0px 10px 34px 0px rgb(255, 213, 172);
  border-radius: 22px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #FFFFFF;
  line-height: 18px;
  text-align: center;
  font-weight: 500;
  margin-right: 3px;
}

.addFont {
  background: linear-gradient(-45.00deg, rgb(255, 206, 0) 0%, rgb(255, 110, 17) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
